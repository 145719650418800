.App {
  /* Using a static color for the background as it is used just once */
  background-color: #e1e2ec; /* surfaceVariantColor */
  min-height: 100vh;
  display: flex;
  align-items: stretch;
  justify-content: center;
}

.form {
  display: flex;
  flex-direction: column;
  justify-content: left;
  margin: 0;
  padding: 84px 22px 22px;
  background-color: #fefbff; /* surfaceColor */
  box-sizing: border-box;
  width: 100%;
}

@media (min-width: 600px) {
  .App {
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
  }

  .form {
    margin-top: 10%;
    margin-left: auto;
    margin-right: auto;
    padding: 44px 42px 22px;
    border-radius: 12px;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.25);
    box-sizing: unset;
    width: 360px;
  }
}
